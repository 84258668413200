<template>
    <v-card class="remove-dialog py-6">
        <div class="text-h6 mb-5 px-6">
            Edit Webhook
        </div>
        <v-divider class="my-4"></v-divider>
        <v-form 
            ref="form"
            lazy-validation
            v-model="valid"
            @submit.prevent="handleSubmit"
            class="px-6 mb-6"
            autocomplete="off"
        >
            <div class="text-subtitle-1 mb-2">Endpoint</div>
            <div class="text-body-2 mb-4">We will send a request to this endpoint when each form response is submitted.</div>
            <v-text-field 
                outlined 
                dense 
                placeholder="https://yourwebsite.com/webhook"  
                v-model="webhookClone.url"
                :rules="urlRules"
                validate-on-blur
            ></v-text-field>
            <div class="mb-4" ></div>
            <div class="text-subtitle-1 mb-2">Secret</div>
            <div class="text-body-2 mb-4">Verify that the request is coming from Voiceform and nobody else.</div>
            <v-text-field 
                outlined 
                dense 
                placeholder="My secret"  
                v-model="webhookClone.secret"
                :rules="secretRules"
                validate-on-blur
                :type="showSecret ? 'text' : 'password'"
                :append-icon="showSecret ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="showSecret = !showSecret"
            ></v-text-field>
            <div class="mb-4" ></div>
            <div class="text-subtitle-1 mb-2">SSL verification</div>
            <div class="text-body-2 mb-4">We verify SSL certificates when delivering payloads.</div>
            <v-radio-group v-model="webhookClone.ssl_verification">
                <v-radio label="On (recommended)" :value="true"></v-radio>
                <v-radio label="Off" :value="false"></v-radio>
            </v-radio-group>
            <div class="mb-4" ></div>
            <div class="text-subtitle-1 mb-2">Delete webhook</div>
            <div class="text-body-2 mb-4">If you delete this webhook, it cannot be recovered. Any voiceform submissions won't get delivered to this endpoint.</div>
            <delete-webhook :webhook="webhook" @deleted="$emit('closeDialogue')"></delete-webhook>
        </v-form>
        <v-divider></v-divider>
        <div class="d-flex px-6 pt-4">
            <v-spacer></v-spacer>
            <v-btn
                text
                @click="$emit('closeDialogue')"
                class="mr-4"
            >
                Cancel
            </v-btn>
            <v-btn
                color="primary"
                :elevation="0"
                @click="handleSubmit"
                :disabled="!changed"
            >
                Save changes
            </v-btn>
        </div>
    </v-card>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import DeleteWebhook from './DeleteWebhook.vue';
const LinkReg = new RegExp("^(http|https)://", "i");
export default {
    props: {
        webhook: {
           required: true
        }
    },
    components: {DeleteWebhook},
    watch: {
        webhook: {
            immediate: true,
            handler(val){
                this.webhookClone = {...val}
            }
        }
    },
    computed:{
        changed(){
            return JSON.stringify(this.webhook) !== JSON.stringify(this.webhookClone)
        }
    },
    data(){
        return {
            webhookClone: this.webhook,
            submittingForm: false,
            valid: true,
            showSecret: false,
            urlRules: [
                value => !!value.trim() || 'The endpoint is required',
                value => !!LinkReg.test(value) || "Endpoint format is invalid. It should start from http:// or https://.",
            ],
            secretRules: [
                value => !value || value.length > 8 || "The secret should be at least 8 characters long"
            ]
        }
    },
    methods: {
        ...mapActions({
            updateWebhook: 'survey/updateWebhook'
        }),
        ...mapMutations(['showSnackBar']),
        async handleSubmit(){
            try {
                const valid = this.$refs.form.validate();
                if(!valid) {
                    return;
                }

                this.submittingForm = true
                await this.updateWebhook({webhookId: this.webhookClone.id, data:this.webhookClone})
                this.submittingForm = false
                this.addWebhookDialogue = false
                this.showSnackBar({
                    color: "success",
                    timeout: 4000,
                    text: "Webhook has been successfully updated."
                })
                this.$emit('closeDialogue')
            } catch (error) {
                this.submittingForm = false
                this.showSnackBar({
                    color: "error",
                    timeout: 4000,
                    text: "Something went wrong. Can't update the webhook. Please try again later."
                })
                console.error('add webhook error:', error);
            }
        }
    }
}
</script>