<template>
    <div>
        <v-btn :loading="submittingForm" :disabled="submittingForm" @click="handleOpenDialogue" class="mt-4" color="primary" elevation="0">
            <v-icon left>mdi-plus</v-icon>
            Add webhook
        </v-btn>
        <v-dialog
                v-model="addWebhookDialogue"
                width="500"
                @click:outside="closeDeleteDialog"
                :persistent="submittingForm"
            >
            <v-card class="remove-dialog pa-6">
                <div class="text-h6 mb-5">
                    Add Webhook
                </div>
                <div class="text-subtitle-1 mb-2">Endpoint</div>
                <div class="text-body-2 mb-4">We will send a request to this endpoint when each form response is submitted.</div>
                <v-form 
                    ref="form"
                    lazy-validation
                    v-model="valid"
                    @submit.prevent="handleSubmit"
                >
                    <v-text-field 
                        outlined 
                        dense 
                        placeholder="https://yourwebsite.com/webhook"  
                        v-model="url"
                        :rules="urlRules"
                        validate-on-blur
                    ></v-text-field>
                </v-form>
                <div class="d-flex">
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        @click="closeDeleteDialog"
                        class="mr-4"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="primary"
                        dark
                        :elevation="0"
                        @click="handleSubmit"
                    >
                        Save webhook
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
    
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
const LinkReg = new RegExp("^(http|https)://", "i");
export default {
    data(){
        return {
            addWebhookDialogue: false,
            valid: true,
            url: '',
            submittingForm: false,
            urlRules: [
                value => !!value.trim() || 'The endpoint is required',
                value => !!LinkReg.test(value) || "Endpoint format is invalid. It should start from http:// or https://.",
            ]
        }
    },
    methods: {
        ...mapActions({
            createWebhook: 'survey/createWebhook'
        }),
        ...mapMutations(['showSnackBar']),
        handleOpenDialogue(){
            this.url= ''
            this.addWebhookDialogue = true

        },
        closeDeleteDialog(){
            this.addWebhookDialogue = false
        },
        async handleSubmit() {
            try {
                const valid = this.$refs.form.validate();
                if(!valid) {
                    return;
                }

                this.submittingForm = true
                await this.createWebhook(this.url)
                this.submittingForm = false
                this.addWebhookDialogue = false
                this.showSnackBar({
                    color: "success",
                    timeout: 4000,
                    text: "Webhook has been added!"
                })
            } catch (error) {
                this.submittingForm = false
                this.showSnackBar({
                    color: "error",
                    timeout: 4000,
                    text: "Something went wrong. Can't add the webhook. Please try again later."
                })
                console.error('add webhook error:', error);
            }
        }
    }
}
</script>