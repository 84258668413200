<template>
    <connect-layout :fluid="false">
        <div class="d-flex">
            <div>
            <v-icon size="60" class="mr-4 mt-2" color="primary">mdi-webhook</v-icon>
            </div>
            <div>
                <div class="text-h5 mb-2">Webhooks</div>
                <div class="text-body-1" style="max-width: 350px">Notify external services when someone completes your voiceform.</div>
                <add-webhook></add-webhook>
            </div>
        </div>
        <v-divider class="my-8"></v-divider>
        <v-container v-if="webhooks && !fetchingWebhooks" class="px-6">
            <div v-if="webhooks.length">
                <v-card v-for="webhook in webhooks" :key="`webhook-${webhook.id}`" outlined class="pa-4 mb-6">
                    <div class="d-flex align-center">
                        <div class="text-body-1 mr-6 ellipsis-link">
                            <v-icon class="mr-2">mdi-link-variant</v-icon>
                            {{webhook.url}}
                        </div>
                        <v-spacer></v-spacer>
                        <v-switch 
                            :loading="updatingWebhookId===webhook.id" 
                            :disabled="updatingWebhookId===webhook.id"  
                            inset 
                            @change="val=>handleSwitchChange(webhook.id, val)" 
                            v-model="webhook.enabled"
                            :label="`Webhook ${webhook.enabled? 'ON':'OFF'}`"
                            style="min-width: 165px;"
                        ></v-switch>
                        <v-btn @click="handleOpenEditWebhook(webhook)" class="ml-6" outlined color="grey darken-1">
                            <v-icon left>mdi-pencil</v-icon>
                            Edit
                        </v-btn>
                    </div>
                </v-card>
            </div>
            <div class="d-flex text-body-1 justify-center mt-4" v-else>
                No webhooks have been added to this voiceform yet.
            </div>
        </v-container>
        <div class="d-flex justify-center align-center" v-else>
            <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </div>
        <v-dialog
                v-model="editWebhookDialogue"
                width="500"
                @click:outside="editWebhookDialogue=false"
            >
            <edit-webhook 
                :webhook="editingWebhook" 
                @closeDialogue="editWebhookDialogue=false">
            </edit-webhook>
        </v-dialog>
    </connect-layout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ConnectLayout from '../../../../../layouts/ConnectLayout.vue'
import AddWebhook from './AddWebhook.vue'
import EditWebhook from './EditWebhook.vue'

export default {
    components: {
        ConnectLayout,
        AddWebhook,
        EditWebhook
    },
    data(){
        return {
            fetchingWebhooks: false,
            editWebhookDialogue: false,
            updatingWebhookId: null,
            editingWebhook: null
        }
    },
    computed: {
        ...mapGetters({
            webhooks: 'survey/webhooks'
        })
    },
    methods: {
        ...mapActions({
            fetchWebhooks: 'survey/fetchWebhooks',
            updateWebhook: 'survey/updateWebhook'
        }),
        ...mapMutations(['showSnackBar']),
        async handleSwitchChange(webhookId, enabled){
            try {
                this.updatingWebhookId = webhookId;
                await this.updateWebhook({webhookId, data:{enabled}})
                this.updatingWebhookId = null;
                this.showSnackBar({
                    color: "success",
                    timeout: 4000,
                    text: `The webhook is updated.`
                })
            } catch (error) {
                this.updatingWebhookId = null;
                console.error('handleSwitchChange webhook error:', error);
                this.showSnackBar({
                    color: "error",
                    timeout: 4000,
                    text: "Something went wrong. Can't update the webhook. Please try again later."
                })
            }
            
        },
        handleOpenEditWebhook(webhook){
            this.editingWebhook = {...webhook}
            this.editWebhookDialogue = true
        }
    },
    async created(){
        if(!this.webhooks){
            this.fetchingWebhooks = true
        }
        await this.fetchWebhooks()
        this.fetchingWebhooks = false
    }
}
</script>

<style lang="scss" scoped>
.ellipsis-link{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>