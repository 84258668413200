<template>
    <div>
        <v-btn @click="deletionConfirmationDialogue=true" elevation="0" class="mt-4" color="error darken-1">
            <v-icon left>mdi-delete</v-icon>
            Delete webhook
        </v-btn>
        <v-dialog
                v-model="deletionConfirmationDialogue"
                width="400"
                @click:outside="deletionConfirmationDialogue=false"
                :persistent="loading"
            >
            <v-card class="pa-6">
                <div class="text-body-2 mb-6">Are you sure you want to delete this webhook?</div>
                <div class="d-flex justify-center">
                    <v-btn
                        text
                        @click="deletionConfirmationDialogue=false"
                        class="mr-4"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="error darken-1"
                        dark
                        :elevation="0"
                        @click="handleDelete"
                        :loading="loading"
                        :disabled="loading"
                    >
                        Yes, delete webhook
                    </v-btn>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
    props: {
        webhook: {
            required: true
        }
    },
    data(){
        return {
            deletionConfirmationDialogue: false,
            loading: false
        }
    },
    methods:{
        ...mapActions({
            deleteWebhook: 'survey/deleteWebhook'
        }),
        ...mapMutations(['showSnackBar']),
        async handleDelete(){
            try {
                this.loading = true
                await this.deleteWebhook(this.webhook.id)
                this.loading = false
                this.showSnackBar({
                    color: "success",
                    timeout: 4000,
                    text: "Webhook has been successfully deleted."
                })
                this.$emit('deleted')
            } catch (error) {
                this.loading = false
                console.error('delete webhook error',error);
                this.showSnackBar({
                    color: "error",
                    timeout: 6000,
                    text: "Something went wrong. Can't delete this webhook. Please try again later."
                })
            }
        }
    }
}
</script>